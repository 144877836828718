<template>
  <section id="connectUs">
    <b-container>
      <b-row>
        <b-col class="breadcrumb_box">
          <span class="icon-icon_home iconfont"></span>
          <b-breadcrumb>
            <b-breadcrumb-item to="/">
              <span>首页</span>
            </b-breadcrumb-item>
            <!-- <b-breadcrumb-item v-for="(item, index) in pathCrumb" :key="index" :to="item.to">{{ item.name }}</b-breadcrumb-item> -->
          </b-breadcrumb>
        </b-col>
      </b-row>

      <b-row class="rectangle_box">
        <b-col>
          <div class="box">
            <div class="content">
              <h3>联系我们</h3>
              <h6>Contact Us</h6>
              <ul>
                <li v-for="(item, index) in concatUsInfoList" :key="index">
                  <span class="iconfont" :class="item.icon"></span>
                  <span>{{ item.text }}</span>
                </li>
              </ul>
            </div>
            <div class="whole_map">
              <img class="whole_map_img" src="../assets/connectUs/whole.jpg" />
              <img class="whole_map_location" src="../assets/connectUs/location.png" />
            </div>
          </div>
          <div class="circle_map_box">
            <div>
              <img class="map_img" :style="'transform:translate(-705px, -594px) scale(' + scale + ')'" src="../assets/connectUs/circle.png" />
              <img class="map_location" src="../assets/connectUs/location.png" />
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import { navItemList, concatUsInfoList } from "@/baseData/index";
export default {
  data() {
    return {
      navItemList,
      concatUsInfoList,
      pathCrumb: [],
      scale: 0.9,
      boxHeight: undefined,
    };
  },
  created() {
    // 获取面包屑导航
    this.navItemList.forEach((element) => {
      if (element.to == this.$store.state.currentPath) {
        this.pathCrumb = [element];
      }
    });
  },

  mounted() {
    // Todo 优化
    setTimeout(() => {
      let boxHeight = document.getElementsByClassName("box")[0].offsetHeight;
      let boxWidth = document.getElementsByClassName("box")[0].offsetWidth;
      if (boxWidth <= 900) {
        this.scale = (boxHeight * 0.9) / 593 - 0.2;
      } else {
        this.scale = (boxHeight * 0.9) / 593;
      }
    }, 0);

    window.onresize = () => {
      return (() => {
        let boxHeight = document.getElementsByClassName("box")[0].offsetHeight;
        let boxWidth = document.getElementsByClassName("box")[0].offsetWidth;
        if (boxWidth <= 900) {
          this.scale = (boxHeight * 0.9) / 593 - 0.2;
        } else {
          this.scale = (boxHeight * 0.9) / 593;
        }
      })();
    };
  },
};
</script>

<style lang="less" scoped>
#connectUs {
  height: 100%;
  height: calc(100vh - 82px - 83px);
  overflow: hidden;
  user-select: text;
}
@media screen and (min-width: 1368px) {
  .container {
    max-width: 1368px !important;
    padding-left: 0;
    padding-right: 0;
    // overflow: hidden;
  }
}
section {
  background: #f0f2f5;
  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    .row {
      margin: 0;
    }
    .breadcrumb_box {
      display: flex;
      align-items: center;
      color: #666666;
      .breadcrumb-item {
        display: flex;
        align-items: center;
      }
      .breadcrumb-item + .breadcrumb-item::before {
        display: inline-block;
        padding-right: 0.5rem;
        color: #6c757d;
        content: "";
        width: 0;
        height: 0;
        border-width: 4px;
        border-style: solid;
        border-bottom-color: transparent;
        border-top-color: transparent;
        border-left-color: #6c757d;
        border-right: 0;
      }
    }
    ol {
      font-size: 1.5rem;
      background-color: #f0f2f5;
      margin-top: 1rem;
      a {
        color: #666666;
        span + span {
          margin-left: 6px;
        }
      }
    }
    .rectangle_box {
      height: 75%;
      // max-height: 75%;
      width: 100%;
      // margin-bottom: 20px;
      margin: auto;
      .box {
        height: 100%;
        position: relative;
        background: #fff;
        // background: #fff url("../assets/connectUs/map2.png") no-repeat
        //     right bottom/auto 90%;
        box-shadow: 0 0 10px #ccc;
        .content {
          position: relative;
          width: 50%;
          height: 100%;
          padding: 0 8rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          h3 {
            font-size: 4.8rem;
            color: #015aab;
            font-weight: 300;
            line-height: 1.4;
          }
          h6 {
            font-size: 1.8rem;
            color: #333;
            opacity: 0.5;
            font-weight: 300;
          }
          ul {
            font-size: 1.6rem;
            margin: 2rem 0 0;
            padding: 0;
            li {
              display: flex;
              align-items: center;
              list-style: none;
              border-bottom: 1px solid #eaeaea;
              transform: translateX(-50%);
              opacity: 0;
              .loop(6);
            }

            .loop(@n, @i:1) when (@i <= @n) {
              &:nth-child(@{i}) {
                animation: trans_l 0.4s cubic-bezier(0.25, 0.1, 0.3, 1.5) @i / 10 * 1s forwards;
              }
              .loop(@n,(@i + 1));
            }

            .iconfont {
              font-size: 2rem;
              color: #eaeaea;
              margin-right: 2rem;
              line-height: 5rem;
            }
          }
        }
        .whole_map {
          display: none;
          position: relative;
          .whole_map_img {
            width: 100%;
          }
          .whole_map_location {
            position: absolute;
            top: 55%;
            left: 51%;
            width: 6%;
            border-radius: 50%;
            animation: ripple 1s linear infinite;
          }
        }
      }
      .circle_map_box {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 0;
        height: 0;
        display: block;
        .map_img {
          position: absolute;
          transform-origin: 690px 593px;
          border-radius: 50%;
          // transform: translate(-705px, -594px) scale(0.85);
        }
        .map_location {
          position: absolute;
          left: 0;
          top: 0;
          border-radius: 50%;
          transform: translate(-310%, -245%) scale(0.7);
          animation: ripple 2s linear infinite;
        }
      }
    }
  }
}
@media screen and (max-width: 1200px) and (min-width: 992px) {
  section {
    .container .rectangle_box .circle_map_box .map_location {
      transform: translate(-250%, -195%) scale(0.6) !important;
    }
  }
}

@media screen and (max-width: 1024px) {
  #connectUs {
    height: auto;
  }
  section {
    .container {
      padding: 0;
      .rectangle_box {
        margin: 0 0 20px;
        .box {
          height: auto;
          .content {
            width: 100%;
            padding: 3rem;
            h3 {
              font-size: 3rem;
            }
          }
        }
        .circle_map_box {
          display: none;
        }
        .whole_map {
          display: block !important;
        }
      }
    }
  }
}
@media screen and (max-width: 320px) {
  #connectUs {
    height: auto;
    overflow: auto;
    .rectangle_box {
      margin-bottom: 2rem !important;
    }
  }
}
@keyframes ripple {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(1, 90, 171, 0.2), 0 0 0 20px rgba(1, 90, 171, 0.2), 0 0 0 40px rgba(1, 90, 171, 0.2);
    box-shadow: 0 0 0 0 rgba(1, 90, 171, 0.2), 0 0 0 20px rgba(1, 90, 171, 0.2), 0 0 0 40px rgba(1, 90, 171, 0.2);
  }
  100% {
    -webkit-box-shadow: 0 0 0 20px rgba(1, 90, 171, 0.2), 0 0 0 40px rgba(1, 90, 171, 0.1), 0 0 0 60px rgba(1, 90, 171, 0);
    box-shadow: 0 0 0 20px rgba(1, 90, 171, 0.2), 0 0 0 40px rgba(1, 90, 171, 0.1), 0 0 0 60px rgba(1, 90, 171, 0);
  }
}
</style>
